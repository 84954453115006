import React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'

import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faQuoteLeft
} from '@fortawesome/free-solid-svg-icons'

import Utils from '../components/utils'


const BlogpostTemp = ({ data, location, pageContext }) => {

  let hasBody = false;
  if (data.post.body !== null) {
    hasBody = true;
  }

  return (
    <Layout>
      <SEO
        pagetitle={`${data.post.title} | ${data.post.category.name}`}
        pagepath={location.pathname}
        pageimg={`https:${data.post.eyecatch.file.url}`}
        pageimgw={data.post.eyecatch.file.details.image.width}
        pageimgh={data.post.eyecatch.file.details.image.height}
      />
      <main>
        <div className="l-blogpost__mv">
          <div className="l-blogpost__above">
            <div className="l-blogpost__bg">
              <StaticImage
                src="../assets/images/noise.png"
                alt="ノイズ"
              />
            </div>
            <h3 className="l-blogpost__hdg">{data.post.title}</h3>

            <dl className="m-quotes__details">
              <dt className="m-quotes__img"></dt>
              <dd className="m-quotes__name">
                <Link to={`/person/${data.post.category.slug}/`}>
                  {data.post.category.name}
                </Link>
              </dd>
            </dl>
          </div>
        </div>
        <div className="l-blogpost">
          <div className="l-wrap">
            <div className="l-blogpost__item">
              <div className="l-blogpost__box">

                <div className="l-blogpost__main">

                  <div className="l-blogpost__trunk">
                    {
                      <p className="l-blogpost__text" key={data.post.id}>
                        <Link to={`/person/${data.post.category.slug}`}>
                          {data.post.category.name}の名言一覧を見る
                        </Link>
                      </p>
                    }
                  </div>

                  {hasBody === true &&
                    <div className="l-blogpost__trunk">
                      <h3 className="m-heading">名言について</h3>
                      <p dangerouslySetInnerHTML={{ __html: data.post.body.body }} />
                    </div>
                  }

                  <div className="l-blogpost__trunk">
                    <h3 className="m-heading">関連名言</h3>
                    <div className='m-quotes'>
                      {data.posts.edges.map(( { node }) => {
                        const num = Math.floor( Math.random() * Utils.color.length )
                        return (
                          <article className={`m-quotes__item ${Utils.color[num]}`} key={node.id}>
                            <Link to={`/${node.category.slug}/${node.slug}/`}>
                              <h3 className="m-quotes__hdg">
                                <FontAwesomeIcon icon={faQuoteLeft} />
                                <span className="m-quotes__txt">{node.title}</span>
                              </h3>
                            </Link>
                          </article>
                        )
                      })}
                    </div>
                  </div>
                </div>

                <div className="l-blogpost__data">

                  <div className="l-blogpost__trunk">
                    <h3 className="m-heading">{data.post.category.name}について</h3>
                    <div className="m-profile">
                      {data.cate.edges.map(( { node }) => {
                        return (
                          <div className="m-profile__detail" key={node.id}>
                            <div className="m-profile__img">
                              <figure>
                                <GatsbyImage
                                  image={node.selfie.gatsbyImageData}
                                  alt={node.selfie.title}
                                />
                              </figure>
                            </div>
                            <div className="m-profile__texts">
                              <p className="m-profile__txt m-cite">
                                {node.informations.informations}
                              </p>
                              <p className="m-profile__sub">引用：<Link to="https://ja.wikipedia.org/wiki/%E3%83%A1%E3%82%A4%E3%83%B3%E3%83%9A%E3%83%BC%E3%82%B8">Wikipedia</Link></p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  <div className="l-blogpost__trunk">
                    <div className="l-blogpost__info--tag info--tag">
                      <h4 className="m-heading">あなたへの名言</h4>
                      <ul className="info__person info__person--tag">
                        <li className="info__item info__item--tag">
                          {data.tag1.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p>{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag2.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag3.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag4.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag5.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag6.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag7.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag8.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag9.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag10.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag11.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag12.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag13.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag14.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag15.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag16.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag17.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag18.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag19.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag20.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag21.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag22.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag23.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag24.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag25.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.tag26.edges.map(( {node }) => {
                            return (
                              <Link to={`/tags/${node.slug}`}>
                                <div className="tag__unit" key={node.id}>
                                  <p className="tag__item__ttl">{node.title}</p>
                                </div>
                              </Link>
                            )
                          })}
                        </li>

                      </ul>
                    </div>
                  </div>



                  <div className="l-blogpost__trunk">
                    <div className="l-blogpost__info--tag info--tag">
                      <h4 className="m-heading">タイプから探す</h4>
                      <ul className="info__person info__person--tag">
                        <li className="info__item info__item--tag">
                          {data.type1.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.type2.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.type3.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.type4.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.type5.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.type6.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.type7.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.type8.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.type9.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.type10.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.type11.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                        <li className="info__item info__item--tag">
                          {data.type12.edges.map(( { node }) => (
                            <Link to={`/tags/${node.slug}`} key={node.id}>
                              <p>{node.title}</p>
                            </Link>
                          ))}
                        </li>

                      </ul>
                    </div>
                  </div>


                  <div className="l-blogpost__trunk">
                    <div className="l-blogpost__info info">
                      <h4 className="m-heading">偉人から探す</h4>
                      <ul className="info__person">
                        {data.allCate.edges.map(( {node }) => {
                          return (
                            <li className="info__item" key={node.id}>
                              <Link to={`/person/${node.slug}`}>
                                <dl className="info__item__details">
                                  <dd className="info__item__img">
                                    <GatsbyImage
                                      className="info__item__photo"
                                      image={node.selfie.gatsbyImageData}
                                      alt={node.selfie.title}
                                    />
                                  </dd>
                                  <dt className="info__item__ttl">{node.name}</dt>
                                </dl>
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!, $catid: String!) {
    post: contentfulBlogPost(id: { eq: $id }) {
      title
      id
      category {
          slug
          name
          id
      }
      eyecatch {
          gatsbyImageData(layout: FULL_WIDTH)
          description
          title
          id
          file {
              details {
                  image {
                      height
                      width
                  }
              }
              url
          }
      }
      body {
          body
      }
    }

    posts: allContentfulBlogPost(
      filter: {category: {id: {eq: $catid}}}
      limit: 10
    ) {
      edges {
        node {
          slug
          title
          id
          category {
            slug
          }
        }
      }
    }

    cate: allContentfulCategory(
      filter: {id: {eq: $catid}}
    ) {
      edges {
        node {
          id
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            title
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 30
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            title
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }


    tag1: allContentfulTags(filter: {title: {eq: "クリエイター・アーティストへの名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag2: allContentfulTags(filter: {title: {eq: "愛について説かれた名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag3: allContentfulTags(filter: {title: {eq: "仏教"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag4: allContentfulTags(filter: {title: {eq: "チャレンジする勇気をくれる名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag5: allContentfulTags(filter: {title: {eq: "健康についての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag6: allContentfulTags(filter: {title: {eq: "社会人のあなたが読むべき名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag7: allContentfulTags(filter: {title: {eq: "友人・友情についての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag8: allContentfulTags(filter: {title: {eq: "働くことについての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag9: allContentfulTags(filter: {title: {eq: "仕事のコツについての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag10: allContentfulTags(filter: {title: {eq: "本について説かれた名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag11: allContentfulTags(filter: {title: {eq: "勉強・学習についての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag12: allContentfulTags(filter: {title: {eq: "幸せ・幸福についての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag13: allContentfulTags(filter: {title: {eq: "習慣についての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag14: allContentfulTags(filter: {title: {eq: "学生への名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag15: allContentfulTags(filter: {title: {eq: "上司・指導者への名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag16: allContentfulTags(filter: {title: {eq: "智慧の名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag17: allContentfulTags(filter: {title: {eq: "成功哲学についての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag18: allContentfulTags(filter: {title: {eq: "主婦に向けた名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag19: allContentfulTags(filter: {title: {eq: "自分は自分のままでいい"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag20: allContentfulTags(filter: {title: {eq: "定年後のあなたへの名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag21: allContentfulTags(filter: {title: {eq: "人生哲学についての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag22: allContentfulTags(filter: {title: {eq: "落ち込んだ人への名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag23: allContentfulTags(filter: {title: {eq: "金・財産についての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag24: allContentfulTags(filter: {title: {eq: "教育・指導についての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag25: allContentfulTags(filter: {title: {eq: "心を癒やす名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    tag26: allContentfulTags(filter: {title: {eq: "ユーモアについての名言"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type1: allContentfulTags(filter: {title: {eq: "実業家"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type2: allContentfulTags(filter: {title: {eq: "マンガ"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type3: allContentfulTags(filter: {title: {eq: "スポーツ選手"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type4: allContentfulTags(filter: {title: {eq: "学者"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type5: allContentfulTags(filter: {title: {eq: "政治家"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type6: allContentfulTags(filter: {title: {eq: "アーティスト"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type7: allContentfulTags(filter: {title: {eq: "戦士"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type8: allContentfulTags(filter: {title: {eq: "教育者"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type9: allContentfulTags(filter: {title: {eq: "小説家"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type10: allContentfulTags(filter: {title: {eq: "思想家"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type11: allContentfulTags(filter: {title: {eq: "改革者"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    type12: allContentfulTags(filter: {title: {eq: "宗教家"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }



  }
`

export default BlogpostTemp